import React from 'react';
import Router from './Routes/index.tsx';

function App() {
  return (
    <Router/>
  );
}

export default App;
