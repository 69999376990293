import React from 'react';
import './Onboard.css';


function Onboard(props) {
  return (
    <div className="onboard">
        <img src="./images/Logo-2.png" className="logo-onb" alt="Nobelbee_logo" />
		<div className="wrapper">
		<input type="radio" name="sli" id="slide1" defaultChecked/>
		<input type="radio" name="sli" id="slide2" />
		<input type="radio" name="sli" id="slide3"/>
		<div className="slider">
		<div className="slides slide1"><div className='slide-title'>Pick the issue you want to address</div></div>
		<div className="slides slide2"><div className='slide-title'>Pick the fund you want to donate</div></div>
		<div className="slides slide3"><div className='slide-title'>Easyly donate from card or Paypal</div></div>
		</div>
		<div className="controls">
		<label className="label1" htmlFor="slide1"></label>
		<label className="label1" htmlFor="slide2"></label>
		<label className="label1" htmlFor="slide3"></label>
		<div className="controls-2">
		<label className="label2" htmlFor="slide1"><p className='slide-title1'>1</p></label>
		<label className="label2" htmlFor="slide2"><p className='slide-title1'>2</p></label>
		<label className="label2" htmlFor="slide3"><p className='slide-title1'>3</p></label>
		</div>
		</div>
		</div>
		<a className='help_button_onb' href='/category'>I want to help right now</a>
    </div>
  );
}

export default Onboard;