import i1 from './fonds_data/pravo-na-zahyst.png';
import i2 from './fonds_data/fond-europe.png';
import i3 from './fonds_data/palanytzia.png';
import i4 from './fonds_data/tabletochki.png';
import i5 from './fonds_data/golosi-ditey.png';
import i6 from './fonds_data/ridni.png';
import i7 from './fonds_data/krona.png';
import i8 from './fonds_data/childua.png';
import i9 from './fonds_data/u-fond.png';
import i10 from './fonds_data/kojen-moje.png';
import i11 from './fonds_data/gyttelub.png';
import i12 from './fonds_data/lets-help.png';
import i13 from './fonds_data/starenki.png';
import i14 from './fonds_data/women-fond.png';
import i15 from './fonds_data/invalid-help.png';
import i16 from './fonds_data/povernys_jyvym.png';
import i17 from './fonds_data/armiya-sos.png';
import i18 from './fonds_data/rozvutka.png';
import i19 from './fonds_data/patriot.png';
import i20 from './fonds_data/kolo.png';
import i21 from './fonds_data/lavita.png';
import i22 from './fonds_data/gosp.png';
import i23 from './fonds_data/moz.png';
import i24 from './fonds_data/happy-pow.png';
// eslint-disable-next-line
export default [
      {
        id: 1,
        name: 'Right to Protection',
        description: [
          'The Right to Protection, Charitable Foundation is one of the leaders of the human rights movement in Ukraine and has a strong position in the field of protection of the rights of IDPs, refugees and stateless persons.',
        ],
        image: i1,
        categories: ['Refugees'],
        link: 'https://r2p.org.ua/help/?lang=en',
        fond: 'https://r2p.org.ua',
      },
      {
        id: 2,
        name: 'East Europe Foundation ("Shelter" Project)',
        description: [
          'The Shelter project aims to provide emergency assistance to those who need it most.',
          'Maintenance of the network of centers for IDPs in the western regions of the country and collection, processing and prompt response to requests for needs from other groups of the population are the main directions of the project.',
        ],
        image: i2,
        categories: ['Refugees'],
        link: 'https://eef.org.ua/program/proyekt-shelter-dopomoga-vymushenym-pereselentsyam/',
        fond: 'https://eef.org.ua',
      },
      {
        id: 3,
        name: 'Palianytsia',
        description: [
          'Ukrainian charity project from Lviv, aimed at helping refugees and injured Ukrainians.',
          "The project provides shelter to refugees arriving in Lviv. They provide humanitarian aid - food, medicine, medical equipment, hygiene products, children's goods - from the EU and other countries and provide psychological and legal assistance to victims.",
        ],
        image: i3,
        categories: ['Refugees'],
        link: 'https://palianytsia.com.ua/',
        fond: 'https://palianytsia.com.ua/',

      },
      {
        id: 4,
        name: 'Tabletochki',
        description: [
          'The main task of "Tabletochok" is to make sure that no child in Ukraine dies from cancer. Since 2011, the Tabletochki Charitable Foundation has been helping Ukrainian families to overcome cancer on a daily basis, and doctors save the lives of children with cancer.',
        ],
        image: i4,
        categories: ['Children'],
        link: 'https://tabletochki.org/en/donate_now/',
        fond: 'https://tabletochki.org',
      },
      {
        id: 5,
        name: 'Voices of children',
        description: [
          'The Voices of Children Foundation have been helping children affected by the war since 2015. Today, during the full-scale Russian invasion of Ukraine, the fund provides non-stop assistance to affected children and families from all over the country, provides emergency psychological assistance, and assists in the evacuation process.',
        ],
        image: i5,
        categories: ['Children'],
        link: 'https://voices.org.ua/en/donat/',
        fond: 'https://voices.org.ua',
      },
      {
        id: 6,
        name: 'Ridni',
        description: [
          'The Ridni Charitable Foundation aims to overcome orphanhood and develop the institution of the family.',
          "All the Foundation's efforts are now focused on supporting and evacuating children and their caregivers. Redone provides targeted assistance to orphans and children deprived of parental care who are close to the war line.",
        ],
        image: i6,
        categories: ['Children'],
        link: 'https://ridni.org.ua/en/donation',
        fond: 'https://ridni.org.ua',
      },
      {
        id: 7,
        name: 'Charitable Foundation "Krona"',
        description: [
          'The All-Ukrainian Charitable Foundation "Krona" provides assistance to seriously ill children and their families, helps to introduce new technologies in medical institutions, to create quality treatment conditions.',
        ],
        image: i7,
        categories: ['Children', 'Women'],
        link: 'https://krona.niko.ua/en/help-page/',
        fond: 'https://krona.niko.ua',
      },
      {
        id: 8,
        name: 'Child.ua',
        description: [
          "The main mission of the fund is to raise the standards of protection of children's rights in Ukraine to the level of the guidelines of the UN Convention on the Rights of the Child, as well as to promote social and humanitarian projects and initiatives of members of the Association.",
        ],
        image: i8,
        categories: ['Children'],
        link: 'https://child.ua/en/donate/',
        fond: 'https://child.ua',
      },
      {
        id: 9,
        name: 'UFond',
        description: [
          'The main direction of the Foundation is targeted fundraising, assistance to children under 18 years of age. Over time, the following areas have been added to the main programs of the Foundation: humanitarian aid to orphanages and medical institutions of Ukraine.',
          'The mission of the foundation is to save seriously ill children, promote the development of civil society, and introduce high medical technologies.',
        ],
        image: i9,
        categories: ['Children'],
        link: 'https://ufond.ua/en/Details.874',
        fond: 'https://ufond.ua',
      },
      {
        id: 10,
        name: 'International Charity Foundation «EverybodyCan»',
        description: [
          'Everyone Can International Charitable Foundation. The mission of the foundation is to develop charity and volunteering, to establish a responsible and tolerant society in Ukraine and to help those who need it the most.',
          "Current areas of the fund's activities: assistance to children, the elderly, hospitals and the development of volunteering and charitable.",
        ],
        image: i10,
        categories: ['Children', 'Older People', 'Medicine'],
        link: 'https://en.everybodycan.com.ua/dopomogti-zaraz',
        fond: 'https://en.everybodycan.com.ua',
      },
      {
        id: 11,
        name: 'Charitable foundation “ENJOYING LIFE”',
        description: [
          'ENJOYING LIFE is a charitable foundation that supports people of advanced age.',
          'During the war, the ENJOYING LIFE Foundation provided a wide range of humanitarian aid. The fund helps all groups of the population engaged in the delivery of humanitarian goods throughout Ukraine, including hot spots. Assists in evacuating victims, providing food, medicine and hygiene to those left alone.',
        ],
        image: i11,
        categories: ['Older People'],
        link: 'https://happyold.com.ua/en/help',
        fond: 'https://happyold.com.ua',
      },
      {
        id: 12,
        name: 'Charitable foundation "Let\'s Help"',
        description: [
          'Since 2015, the "Let\'s Help" Charitable Foundation has been systematically helping the elderly and working to create a decent old age in Ukraine. Tens of thousands of elderly people and 127 geriatric boarding houses in 10 regions of Ukraine are under the care of the foundation.',
        ],
        image: i12,
        categories: ['Older People'],
        link: 'https://letshelp.com.ua/donate',
        fond: 'https://letshelp.com.ua',
      },
      {
        id: 13,
        name: 'Starenki',
        description: [
          'Starenki is a charitable foundation, which has been helping lonely elderly people in difficult life circumstances since 2017. The Foundation provides food aid and organizes a community of like-minded people who are not indifferent to the fate of the elderly and the topic of old age in Ukraine.',
        ],
        image: i13,
        categories: ['Older People'],
        link: 'https://starenki.com.ua/en/donate/',
        fond: 'https://starenki.com.ua',
      },
      {
        id: 14,
        name: "Ukrainian Women's Fund",
        description: [
          "The main mission of the Ukrainian Women's Foundation is to support women's / feminist organizations, promote their participation in the development of a powerful, effective and mass women's / feminist movement that can protect women's rights and promote gender equality in all areas.",
        ],
        image: i14,
        categories: ['Women'],
        link: 'https://uwf.org.ua/en/donate/',
        fond: 'https://uwf.org.ua',
      },
      {
        id: 15,
        name: 'Charitable Foundation "Assistance to the Disabled of Ukraine"',
        description: [
          'The mission of the foundation is active participation and comprehensive assistance in creating decent and active living conditions for people with disabilities. The Foundation promotes and maintains effective interaction between public organizations, good people and recipients.',
        ],
        image: i15,
        categories: ['People with disabilities'],
        link: 'http://ukrhelp.net/en/helppage.html',
        fond: 'http://ukrhelp.net',
      },
      {
        id: 16,
        name: 'Come Back Alive',
        description: [
          "Come Back Alive Foundation next to the Ukrainian military. An important area of ​​the organization's work is to support those who defended Ukraine from the aggressor troops with weapons in their hands. The Return Alive Foundation is working to make the army effective. Our weapons are specialists and experience.",
        ],
        image: i16,
        categories: ['Army'],
        link: 'https://savelife.in.ua/en/donate-en/',
        fond: 'https://savelife.in.ua',
      },
      {
        id: 17,
        name: 'Army SOS',
        description: [
          "A fund to help improve Ukraine's defense capabilities since 2014. The main specialization is the purchase of necessary military ammunition, protection, communications and intelligence, uniforms and food, as well as their delivery directly to the front.",
        ],
        image: i17,
        categories: ['Army'],
        link: 'https://armysos.com.ua/donate/',
        fond: 'https://armysos.com.ua',
      },
      {
        id: 18,
        name: 'CHARITABLE FOUNDATION DEVELOPMENT AND SECURITY FOR UKRAINE',
        description: [
          'The purpose of the fund: to support the citizens of Ukraine during the difficult war period. Main directions: reconstruction of housing / provision of temporary housing, provision of legal support to victims, financing of humanitarian support programs, assistance to the Armed Forces in material support.',
        ],
        image: i18,
        categories: ['Army'],
        link: 'https://charityukraine.com.ua/en/home-english/#painfos',
        fond: 'https://charityukraine.com.ua',
      },
      {
        id: 19,
        name: 'All-Ukrainian Association "Patriot"',
        description: [
          'All-Ukrainian Union "Patriot" - material and social support of the military. With the funds raised, volunteers buy helmets, uniforms, weapons, and ammunition for soldiers at the front.',
        ],
        image: i19,
        categories: ['Army'],
        link: 'https://www.facebook.com/PatriotGO',
        fond: 'https://www.facebook.com/PatriotGO',
      },
      {
        id: 20,
        name: 'KOLO',
        description: [
          'KOLO is a team of top managers of product companies that have created a fund to provide operational assistance to the Armed Forces.',
          'The fund focuses on technical equipment: drones, thermal imagers, walkie-talkies, bulletproof vests.',
        ],
        image: i20,
        categories: ['Army'],
        link: 'https://www.koloua.com/en',
        fond: 'https://www.koloua.com',
      },
      {
        id: 21,
        name: 'Palliative Care Fund “LaVita”',
        description: [
          'The foundation helps seriously and incurably patients and their families, patients in hospices and palliative care departments, hospices and their staff.',
        ],
        image: i21,
        categories: ['Medicine'],
        link: 'https://lavita.fund/en/help',
        fond: 'https://lavita.fund',
      },
      {
        id: 22,
        name: 'Hospitallers',
        description: [
          'Hospitallers is a voluntary organization of paramedics. It was founded by Yana Zinkevych at the beginning of hostilities in Ukraine in 2014. Russia then annexed Crimea and launched hostilities in the east.',
          'The slogan of our organization is "For the sake of every life". And the Hospitallers are proving every day that these are not just words for us.',
        ],
        image: i22,
        categories: ['Medicine'],
        link: 'https://www.hospitallers.life/needs-hospitallers',
        fond: 'https://www.hospitallers.life',
      },
      {
        id: 23,
        name: 'Account of the Ministry of Health and the Red Cross',
        description: [
          'The Ministry of Health of Ukraine together with the Red Cross Society of Ukraine is opening a special account to help health care institutions. On this account, every Ukrainian, every company and organization from Ukraine and friends of our country can transfer all possible financial assistance to support hospitals in rescuing the defenders of Ukraine.',
        ],
        image: i23,
        categories: ['Medicine'],
        link: 'https://moz.gov.ua/article/news/moz-ta-chervonij-hrest-vidkrivajut-rahunok-dlja-dopomogi-medikam',
        fond: 'https://moz.gov.ua/article/news/moz-ta-chervonij-hrest-vidkrivajut-rahunok-dlja-dopomogi-medikam',
      },
      {
        id: 24,
        name: 'Happy Paw',
        description: [
          'Happy Paw is a charitable foundation that cares for the fate of homeless cats and dogs.',
          'The foundation also serves as a shelter for homeless animals.',
        ],
        image: i24,
        categories: ['Animals'],
        link: 'https://happypaw.ua/en/contribution',
        fond: 'https://happypaw.ua',
      },
    ];
  