import i7 from './fonds_data/krona.png';
import i14 from './fonds_data/women-fond.png';

// eslint-disable-next-line
export default [
      {
        id: 7,
        name: 'Charitable Foundation "Krona"',
        description: [
          'The All-Ukrainian Charitable Foundation "Krona" provides assistance to seriously ill children and their families, helps to introduce new technologies in medical institutions, to create quality treatment conditions.',
        ],
        image: i7,
        categories: ['Children', 'Women'],
        link: 'https://krona.niko.ua/en/help-page/',
        fond: 'https://krona.niko.ua',
      },
      {
        id: 14,
        name: "Ukrainian Women's Fund",
        description: [
          "The main mission of the Ukrainian Women's Foundation is to support women's / feminist organizations, promote their participation in the development of a powerful, effective and mass women's / feminist movement that can protect women's rights and promote gender equality in all areas.",
        ],
        image: i14,
        categories: ['Women'],
        link: 'https://uwf.org.ua/en/donate/',
        fond: 'https://uwf.org.ua',

      },
    ];
  