import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="Home">
        <p className='title1'>Ukraine is under russian attack. <img src="./images/Icon_Heart.png" className="icon_heart" alt="Icon_Heart" /></p>
        
        <p className='title2'> You can help Ukrainians to survive.<br /><br /> Donate to approved reliable<br /> handpicked funds. Choose it based<br /> on the issue you want to address </p>
        <a className='how_it_works' href='/onboard'>How it works</a>
        <a className='help_button' href='/category'>I want to help right now</a>
    </div>
  );
};

export default Home;
