import React from 'react';
import './old_people_models.css';
import Modal from "../Fond/modal_fond";

function OldPeopleModels({ old }) {
    const [isModal, setModal] = React.useState(false);
    return (
            <div className='old-item'><img
                className='old-image'
                src={old.image}
                alt={old.name}/>
                <p onClick={() => setModal(true)} className='old-name'>{old.name}</p>
                <Modal
                isVisible={isModal}
                title={old.name}
                imagefond={old.image}
                content={old.description}
                linkfond={old.fond}
                onClose={() => setModal(false)}
                />
                <a href={old.link} className="donate_button_old">Donate</a>
            </div>
    );
}

export default OldPeopleModels;