import React from 'react';
import refugees from '../refugees';
import RefugeesModels from './refugees_models.jsx';
import './refugees.css';
import { Link } from 'react-router-dom';

function Refugees(props) {
  return (
    <div className="refugees">
      <Link to="/category"><p className='pick_list'>Refugees</p></Link>
      <img src="./images/Union.png" className="Union" alt="Union" />
    <div className='refugee'>
                    <div className='container-refugeess'>
                        {refugees.map(refugee => (
                            <RefugeesModels key={refugee.id} refugee={refugee} />
                        ))}
                    </div>
                </div>
                <Link to="/category/fonds"><button className='directly_button'>I want to pick fund directly</button></Link>
    </div>
  );
}

export default Refugees;