import i16 from './fonds_data/povernys_jyvym.png';
import i17 from './fonds_data/armiya-sos.png';
import i18 from './fonds_data/rozvutka.png';
import i19 from './fonds_data/patriot.png';
import i20 from './fonds_data/kolo.png';

// eslint-disable-next-line
export default [
      {
        id: 16,
        name: 'Come Back Alive',
        description: [
          "Come Back Alive Foundation next to the Ukrainian military. An important area of ​​the organization's work is to support those who defended Ukraine from the aggressor troops with weapons in their hands. The Return Alive Foundation is working to make the army effective. Our weapons are specialists and experience.",
        ],
        image: i16,
        categories: ['Army'],
        link: 'https://savelife.in.ua/en/donate-en/',
        fond: 'https://savelife.in.ua',
      },
      {
        id: 17,
        name: 'Army SOS',
        description: [
          "A fund to help improve Ukraine's defense capabilities since 2014. The main specialization is the purchase of necessary military ammunition, protection, communications and intelligence, uniforms and food, as well as their delivery directly to the front.",
        ],
        image: i17,
        categories: ['Army'],
        link: 'https://armysos.com.ua/donate/',
        fond: 'https://armysos.com.ua',
      },
      {
        id: 18,
        name: 'CHARITABLE FOUNDATION DEVELOPMENT AND SECURITY FOR UKRAINE',
        description: [
          'The purpose of the fund: to support the citizens of Ukraine during the difficult war period. Main directions: reconstruction of housing / provision of temporary housing, provision of legal support to victims, financing of humanitarian support programs, assistance to the Armed Forces in material support.',
        ],
        image: i18,
        categories: ['Army'],
        link: 'https://charityukraine.com.ua/en/home-english/#painfos',
        fond: 'https://charityukraine.com.ua',
      },
      {
        id: 19,
        name: 'All-Ukrainian Association "Patriot"',
        description: [
          'All-Ukrainian Union "Patriot" - material and social support of the military. With the funds raised, volunteers buy helmets, uniforms, weapons, and ammunition for soldiers at the front.',
        ],
        image: i19,
        categories: ['Army'],
        link: 'https://www.facebook.com/PatriotGO',
        fond: 'https://www.facebook.com/PatriotGO',
      },
      {
        id: 20,
        name: 'KOLO',
        description: [
          'KOLO is a team of top managers of product companies that have created a fund to provide operational assistance to the Armed Forces.',
          'The fund focuses on technical equipment: drones, thermal imagers, walkie-talkies, bulletproof vests.',
        ],
        image: i20,
        categories: ['Army'],
        link: 'https://www.koloua.com/en',
        fond: 'https://www.koloua.com',
      },
    ];
  