import React from 'react';
import './disabilities_models.css';
import Modal from "../Fond/modal_fond";

function DisabilitiesModels({ disabilitie }) {
    const [isModal, setModal] = React.useState(false);
    return (
            <div className='disabilitie-item'><img
                className='disabilitie-image'
                src={disabilitie.image}
                alt={disabilitie.name}/>
                <p onClick={() => setModal(true)} className='disabilitie-name'>{disabilitie.name}</p>
                <Modal
                isVisible={isModal}
                title={disabilitie.name}
                imagefond={disabilitie.image}
                content={disabilitie.description}
                linkfond={disabilitie.fond}
                onClose={() => setModal(false)}
                />
                <a href={disabilitie.link} className="donate_button_disabilitie">Donate</a>
            </div>
    );
}

export default DisabilitiesModels;