import React from 'react';
import fonds from '../fonds';
import FondModels from './fond_models.jsx';
import './fond.css';
import { Link } from 'react-router-dom';

function Fond(props) {
  return (
    <div className="fonds">
      <Link to="/category"><p className='pick_list'>Pick the issue from the list</p></Link>
      <img src="./images/Union.png" className="Union" alt="Union" />
    <div className='fond'>
                    <div className='container-fond'>
                        {fonds.map(fond => (
                            <FondModels key={fond.id} fond={fond} />
                        ))}
                    </div>
                </div>
    </div>
  );
}

export default Fond;