import i15 from './fonds_data/invalid-help.png';

// eslint-disable-next-line
export default [
      {
        id: 15,
        name: 'Charitable Foundation "Assistance to the Disabled of Ukraine"',
        description: [
          'The mission of the foundation is active participation and comprehensive assistance in creating decent and active living conditions for people with disabilities. The Foundation promotes and maintains effective interaction between public organizations, good people and recipients.',
        ],
        image: i15,
        categories: ['People with disabilities'],
        link: 'http://ukrhelp.net/en/helppage.html',
        fond: 'http://ukrhelp.net',
      },
    ];
  