import React from 'react';
import './Category.css';
import works from '../works';
import CategoryModels from './Models';
import { Link } from 'react-router-dom';

function Category(props) {
  return (
    <div className="Category">
      <Link to="/"><p className='pick_list'>Pick the issue from the list</p>
      <img src="./images/Union.png" className="Union" alt="Union" /></Link>
    <main className='main'>
    <div className='portfolio'>
                    <div className='container'>
                        {works.map(work => (
                            <CategoryModels key={work.id} work={work} />
                        ))}
                    </div>
                </div>
    </main>
    <a className='directly_button' href='/fonds'>I want to pick fund directly</a>
    </div>
  );
}

export default Category;