import React from 'react';
import army from '../army';
import ArmyModels from './army_models.jsx';
import './army.css';
import { Link } from 'react-router-dom';

function Army(props) {
  return (
    <div className="army">
      <Link to="/category"><p className='pick_list'>Army</p></Link>
      <img src="./images/Union.png" className="Union" alt="Union" />
    <div className='armys'>
                    <div className='container-armys'>
                        {army.map(armys => (
                            <ArmyModels key={armys.id} armys={armys} />
                        ))}
                    </div>
                </div>
                <Link to="/category/fonds"><button className='directly_button'>I want to pick fund directly</button></Link>
    </div>
  );
}

export default Army;