import i10 from './fonds_data/kojen-moje.png';
import i21 from './fonds_data/lavita.png';
import i22 from './fonds_data/gosp.png';
import i23 from './fonds_data/moz.png';
// eslint-disable-next-line
export default [
      {
        id: 10,
        name: 'International Charity Foundation «EverybodyCan»',
        description: [
          'Everyone Can International Charitable Foundation. The mission of the foundation is to develop charity and volunteering, to establish a responsible and tolerant society in Ukraine and to help those who need it the most.',
          "Current areas of the fund's activities: assistance to children, the elderly, hospitals and the development of volunteering and charitable.",
        ],
        image: i10,
        categories: ['Children', 'Older People', 'Medicine'],
        link: 'https://en.everybodycan.com.ua/dopomogti-zaraz',
        fond: 'https://en.everybodycan.com.ua',
      }, 
      {
        id: 21,
        name: 'Palliative Care Fund “LaVita”',
        description: [
          'The foundation helps seriously and incurably patients and their families, patients in hospices and palliative care departments, hospices and their staff.',
        ],
        image: i21,
        categories: ['Medicine'],
        link: 'https://lavita.fund/en/help',
        fond: 'https://lavita.fund',
      },
      {
        id: 22,
        name: 'Hospitallers',
        description: [
          'Hospitallers is a voluntary organization of paramedics. It was founded by Yana Zinkevych at the beginning of hostilities in Ukraine in 2014. Russia then annexed Crimea and launched hostilities in the east.',
          'The slogan of our organization is "For the sake of every life". And the Hospitallers are proving every day that these are not just words for us.',
        ],
        image: i22,
        categories: ['Medicine'],
        link: 'https://www.hospitallers.life/needs-hospitallers',
        fond: 'https://www.hospitallers.life',
      },
      {
        id: 23,
        name: 'Account of the Ministry of Health and the Red Cross',
        description: [
          'The Ministry of Health of Ukraine together with the Red Cross Society of Ukraine is opening a special account to help health care institutions. On this account, every Ukrainian, every company and organization from Ukraine and friends of our country can transfer all possible financial assistance to support hospitals in rescuing the defenders of Ukraine.',
        ],
        image: i23,
        categories: ['Medicine'],
        link: 'https://moz.gov.ua/article/news/moz-ta-chervonij-hrest-vidkrivajut-rahunok-dlja-dopomogi-medikam',
        fond: 'https://moz.gov.ua/article/news/moz-ta-chervonij-hrest-vidkrivajut-rahunok-dlja-dopomogi-medikam',
      },
    ];
  