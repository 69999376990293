import React from 'react';
import medicine from '../medicine';
import MedicineModels from './medicine_models.jsx';
import './medicine.css';
import { Link } from 'react-router-dom';

function Medicine(props) {
  return (
    <div className="medicines">
      <Link to="/category"><p className='pick_list'>Medicine</p></Link>
      <img src="./images/Union.png" className="Union" alt="Union" />
    <div className='medicine'>
                    <div className='container-medicine'>
                        {medicine.map(medicines => (
                            <MedicineModels key={medicines.id} medicines={medicines} />
                        ))}
                    </div>
                </div>
                <Link to="/category/fonds"><button className='directly_button'>I want to pick fund directly</button></Link>
    </div>
  );
}

export default Medicine;