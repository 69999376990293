import React from 'react';
import animals from '../animals';
import AnimalModels from './animals_models.jsx';
import './animals.css';
import { Link } from 'react-router-dom';

function Animals(props) {
  return (
    <div className="animals">
      <Link to="/category"><p className='pick_list'>Animals</p></Link>
      <img src="./images/Union.png" className="Union" alt="Union" />
    <div className='animal'>
                    <div className='container-animal'>
                        {animals.map(animal => (
                            <AnimalModels key={animal.id} animal={animal} />
                        ))}
                    </div>
                </div>
                <Link to="/category/fonds"><button className='directly_button'>I want to pick fund directly</button></Link>
    </div>
  );
}

export default Animals;