import React from 'react';
import './children_models.css';
import Modal from "../Fond/modal_fond";


function ChildrenModels({ children }) {
    const [isModal, setModal] = React.useState(false);
    return (
            <div className='children-item'><img
                className='children-image'
                src={children.image}
                alt={children.name}/>
                <p onClick={() => setModal(true)} className='children-name'>{children.name}</p>
                <Modal
                isVisible={isModal}
                title={children.name}
                imagefond={children.image}
                content={children.description}
                linkfond={children.fond}
                onClose={() => setModal(false)}
                />
                <a href={children.link} className="donate_button_child">Donate</a>
            </div>
    );
}

export default ChildrenModels;