import i24 from './fonds_data/happy-pow.png';
// eslint-disable-next-line
export default [
      {
        id: 24,
        name: 'Happy Paw',
        description: [
          'Happy Paw is a charitable foundation that cares for the fate of homeless cats and dogs.',
          'The foundation also serves as a shelter for homeless animals.',
        ],
        image: i24,
        categories: ['Animals'],
        link: 'https://happypaw.ua/en/contribution',
        fond: 'https://happypaw.ua',
      },
    ];
  