import React from 'react';
import childrens from '../childrens';
import ChildrenModels from './children_models.jsx';
import './children.css';
import { Link } from 'react-router-dom';

function Childrens(props) {
  return (
    <div className="Children">
      <Link to="/category"><p className='pick_list'>Children</p></Link>
      <img src="./images/Union.png" className="Union" alt="Union" />
    <div className='Childre'>
                    <div className='container-childrens'>
                        {childrens.map(children => (
                            <ChildrenModels key={children.id} children={children} />
                        ))}
                    </div>
                </div>
                <Link to="/category/fonds"><button className='directly_button'>I want to pick fund directly</button></Link>
    </div>
  );
}

export default Childrens;