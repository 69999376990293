import React from 'react';
import woman from '../woman';
import WomansModels from './woman_models.jsx';
import './woman.css';
import { Link } from 'react-router-dom';

function Womans(props) {
  return (
    <div className="womans">
      <Link to="/category"><p className='pick_list'>Woman</p></Link>
      <img src="./images/Union.png" className="Union" alt="Union" />
    <div className='woman'>
                    <div className='container-woman'>
                        {woman.map(womans => (
                            <WomansModels key={womans.id} womans={womans} />
                        ))}
                    </div>
                </div>
                <Link to="/category/fonds"><button className='directly_button'>I want to pick fund directly</button></Link>
    </div>
  );
}

export default Womans;