import React from 'react';
import './woman_models.css';
import Modal from "../Fond/modal_fond";

function WomansModels({ womans }) {
const [isModal, setModal] = React.useState(false);
    return (
            <div className='womans-item'><img
                className='womans-image'
                src={womans.image}
                alt={womans.name}/>
                <p onClick={() => setModal(true)} className='womans-name'>{womans.name}</p>
                <Modal
                isVisible={isModal}
                title={womans.name}
                imagefond={womans.image}
                content={womans.description}
                linkfond={womans.fond}
                onClose={() => setModal(false)}
                />
                <a href={womans.link} className="donate_button_womans">Donate</a>
            </div>
    );
}

export default WomansModels;