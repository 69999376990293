import i10 from './fonds_data/kojen-moje.png';
import i11 from './fonds_data/gyttelub.png';
import i12 from './fonds_data/lets-help.png';
import i13 from './fonds_data/starenki.png';

// eslint-disable-next-line
export default [
      {
        id: 10,
        name: 'International Charity Foundation «EverybodyCan»',
        description: [
          'Everyone Can International Charitable Foundation. The mission of the foundation is to develop charity and volunteering, to establish a responsible and tolerant society in Ukraine and to help those who need it the most.',
          "Current areas of the fund's activities: assistance to children, the elderly, hospitals and the development of volunteering and charitable.",
        ],
        image: i10,
        categories: ['Children', 'Older People', 'Medicine'],
        link: 'https://en.everybodycan.com.ua/dopomogti-zaraz',
        fond: 'https://en.everybodycan.com.ua',
      },
      {
        id: 11,
        name: 'Charitable foundation “ENJOYING LIFE”',
        description: [
          'ENJOYING LIFE is a charitable foundation that supports people of advanced age.',
          'During the war, the ENJOYING LIFE Foundation provided a wide range of humanitarian aid. The fund helps all groups of the population engaged in the delivery of humanitarian goods throughout Ukraine, including hot spots. Assists in evacuating victims, providing food, medicine and hygiene to those left alone.',
        ],
        image: i11,
        categories: ['Older People'],
        link: 'https://happyold.com.ua/en/help',
        fond: 'https://happyold.com.ua',
      },
      {
        id: 12,
        name: 'Charitable foundation "Let\'s Help"',
        description: [
          'Since 2015, the "Let\'s Help" Charitable Foundation has been systematically helping the elderly and working to create a decent old age in Ukraine. Tens of thousands of elderly people and 127 geriatric boarding houses in 10 regions of Ukraine are under the care of the foundation.',
        ],
        image: i12,
        categories: ['Older People'],
        link: 'https://letshelp.com.ua/donate',
        fond: 'https://letshelp.com.ua',
      },
      {
        id: 13,
        name: 'Starenki',
        description: [
          'Starenki is a charitable foundation, which has been helping lonely elderly people in difficult life circumstances since 2017. The Foundation provides food aid and organizes a community of like-minded people who are not indifferent to the fate of the elderly and the topic of old age in Ukraine.',
        ],
        image: i13,
        categories: ['Older People'],
        link: 'https://starenki.com.ua/en/donate/',
        fond: 'https://starenki.com.ua',
      },
    ];
  