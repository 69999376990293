import React from 'react';
import './models.css';

function CategoryModels({ work }) {
    return (
        <a href={work.link} className='category-item'>
            <img
                className='category-item__screenshot'
                src={work.screenshot}
                alt={work.title}
            />
            <div className='category-item__title'>{work.title}</div>
        </a>
    );
}

export default CategoryModels;