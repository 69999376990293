import React from 'react';
import old_people from '../old_people';
import OldPeopleModels from './old_people_models.jsx';
import './old_people.css';
import { Link } from 'react-router-dom';

function OldPeople(props) {
  return (
    <div className="olds">
      <Link to="/category"><p className='pick_list'>Older people</p></Link>
      <img src="./images/Union.png" className="Union" alt="Union" />
    <div className='old'>
                    <div className='container-olds'>
                        {old_people.map(old => (
                            <OldPeopleModels key={old.id} old={old} />
                        ))}
                    </div>
                </div>
                <Link to="/category/fonds"><button className='directly_button'>I want to pick fund directly</button></Link>
    </div>
  );
}

export default OldPeople;