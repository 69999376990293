import spa1 from './works/children.png';
import spa2 from './works/refugees.png';
import spa3 from './works/woman.png';
import spa4 from './works/older-people.png';
import spa5 from './works/people-with-disabilities.png';
import spa6 from './works/medicines.png';
import spa7 from './works/animals.png';
import spa8 from './works/army.png';
// eslint-disable-next-line
export default [
    {
        id: 'spa-1',
        title: 'Children',
        screenshot: spa1,
        description: 'Children',
        link: '/childrens'
    },
    {
        id: 'spa-2',
        title: 'Refugees',
        screenshot: spa2,
        description: 'Refugees',
        link: '/refugees'
    },
    {
        id: 'spa-3',
        title: 'Women',
        screenshot: spa3,
        description: 'Woman',
        link: '/woman'
    },
    {
        id: 'spa-4',
        title: 'Older people',
        screenshot: spa4,
        description: 'Older people',
        link: '/older_people'
    },
    {
        id: 'spa-5',
        title: 'People with disabilities',
        screenshot: spa5,
        description: 'People with disabilities',
        link: '/people_with_disabilities'
    },
    {
        id: 'spa-6',
        title: 'Medicine',
        screenshot: spa6,
        description: 'Medicine',
        link: '/medicine'
    },
    {
        id: 'spa-7',
        title: 'Animals',
        screenshot: spa7,
        description: 'Animals',
        link: '/animals'
    },
    {
        id: 'spa-8',
        title: 'Army',
        screenshot: spa8,
        description: 'Army',
        link: '/army'
    }
];