import React from 'react';
import './fond_models.css';
import Modal from "./modal_fond";

function FondModels({ fond }) {
const [isModal, setModal] = React.useState(false);
    return (
            <div className='fond-item'><img
                className='fond-image'
                src={fond.image}
                alt={fond.name}/>
                <p onClick={() => setModal(true)} className='fond-name'>{fond.name}</p>
                <Modal
                isVisible={isModal}
                title={fond.name}
                imagefond={fond.image}
                content={fond.description}
                linkfond={fond.fond}
                onClose={() => setModal(false)}
                />
                <a href={fond.link} className="donate_button_fond">Donate</a>
            </div>
    );
}

export default FondModels;