import React from 'react';
import './army_models.css';
import Modal from "../Fond/modal_fond";

function ArmyModels({ armys }) {
    const [isModal, setModal] = React.useState(false);
    return (
            <div className='armys-item'><img
                className='armys-image'
                src={armys.image}
                alt={armys.name}/>
                <p onClick={() => setModal(true)} className='armys-name'>{armys.name}</p>
                <Modal
                isVisible={isModal}
                title={armys.name}
                imagefond={armys.image}
                content={armys.description}
                linkfond={armys.fond}
                onClose={() => setModal(false)}
                />
                <a href={armys.link} className="donate_button_armys">Donate</a>
            </div>
    );
}

export default ArmyModels;