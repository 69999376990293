import React from 'react';
import './medicine_models.css';
import Modal from "../Fond/modal_fond";

function MedicineModels({ medicines }) {
    const [isModal, setModal] = React.useState(false);
    return (
            <div className='medicine-item'><img
                className='medicine-image'
                src={medicines.image}
                alt={medicines.name}/>
                <p onClick={() => setModal(true)} className='medicine-name'>{medicines.name}</p>
                <Modal
                isVisible={isModal}
                title={medicines.name}
                imagefond={medicines.image}
                content={medicines.description}
                linkfond={medicines.fond}
                onClose={() => setModal(false)}
                />
                <a href={medicines.link} className="donate_button_medicine">Donate</a>
            </div>
    );
}

export default MedicineModels;