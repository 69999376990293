
import i4 from './fonds_data/tabletochki.png';
import i5 from './fonds_data/golosi-ditey.png';
import i6 from './fonds_data/ridni.png';
import i7 from './fonds_data/krona.png';
import i8 from './fonds_data/childua.png';
import i9 from './fonds_data/u-fond.png';
import i10 from './fonds_data/kojen-moje.png';
// eslint-disable-next-line
export default [
      {
        id: 4,
        name: 'Tabletochki',
        description: [
          'The main task of "Tabletochok" is to make sure that no child in Ukraine dies from cancer. Since 2011, the Tabletochki Charitable Foundation has been helping Ukrainian families to overcome cancer on a daily basis, and doctors save the lives of children with cancer.',
        ],
        image: i4,
        categories: ['Children'],
        link: 'https://tabletochki.org/en/donate_now/',
        fond: 'https://tabletochki.org',
      },
      {
        id: 5,
        name: 'Voices of children',
        description: [
          'The Voices of Children Foundation have been helping children affected by the war since 2015. Today, during the full-scale Russian invasion of Ukraine, the fund provides non-stop assistance to affected children and families from all over the country, provides emergency psychological assistance, and assists in the evacuation process.',
        ],
        image: i5,
        categories: ['Children'],
        link: 'https://voices.org.ua/en/donat/',
        fond: 'https://voices.org.ua',
      },
      {
        id: 6,
        name: 'Ridni',
        description: [
          'The Ridni Charitable Foundation aims to overcome orphanhood and develop the institution of the family.',
          "All the Foundation's efforts are now focused on supporting and evacuating children and their caregivers. Redone provides targeted assistance to orphans and children deprived of parental care who are close to the war line.",
        ],
        image: i6,
        categories: ['Children'],
        link: 'https://ridni.org.ua/en/donation',
        fond: 'https://ridni.org.ua',
      },
      {
        id: 7,
        name: 'Charitable Foundation "Krona"',
        description: [
          'The All-Ukrainian Charitable Foundation "Krona" provides assistance to seriously ill children and their families, helps to introduce new technologies in medical institutions, to create quality treatment conditions.',
        ],
        image: i7,
        categories: ['Children', 'Women'],
        link: 'https://krona.niko.ua/en/help-page/',
        fond: 'https://krona.niko.ua',
      },
      {
        id: 8,
        name: 'Child.ua',
        description: [
          "The main mission of the fund is to raise the standards of protection of children's rights in Ukraine to the level of the guidelines of the UN Convention on the Rights of the Child, as well as to promote social and humanitarian projects and initiatives of members of the Association.",
        ],
        image: i8,
        categories: ['Children'],
        link: 'https://child.ua/en/donate/',
        fond: 'https://child.ua',
      },
      {
        id: 9,
        name: 'UFond',
        description: [
          'The main direction of the Foundation is targeted fundraising, assistance to children under 18 years of age. Over time, the following areas have been added to the main programs of the Foundation: humanitarian aid to orphanages and medical institutions of Ukraine.',
          'The mission of the foundation is to save seriously ill children, promote the development of civil society, and introduce high medical technologies.',
        ],
        image: i9,
        categories: ['Children'],
        link: 'https://ufond.ua/en/Details.874',
        fond: 'https://ufond.ua',
      },
      {
        id: 10,
        name: 'International Charity Foundation «EverybodyCan»',
        description: [
          'Everyone Can International Charitable Foundation. The mission of the foundation is to develop charity and volunteering, to establish a responsible and tolerant society in Ukraine and to help those who need it the most.',
          "Current areas of the fund's activities: assistance to children, the elderly, hospitals and the development of volunteering and charitable.",
        ],
        image: i10,
        categories: ['Children', 'Older People', 'Medicine'],
        link: 'https://en.everybodycan.com.ua/dopomogti-zaraz',
        fond: 'https://en.everybodycan.com.ua',
      },
    ];