import React from 'react';
import disabilities from '../disabilities';
import DisabilitiesModels from './disabilities_models.jsx';
import './disabilities.css';
import { Link } from 'react-router-dom';

function Disabilities(props) {
  return (
    <div className="disabilities">
      <Link to="/category"><p className='pick_list'>People with disabilities</p></Link>
      <img src="./images/Union.png" className="Union" alt="Union" />
    <div className='disabilitie'>
                    <div className='container-disabilities'>
                        {disabilities.map(disabilitie => (
                            <DisabilitiesModels key={disabilitie.id} disabilitie={disabilitie} />
                        ))}
                    </div>
                </div>
                <Link to="/category/fonds"><button className='directly_button'>I want to pick fund directly</button></Link>
    </div>
  );
}

export default Disabilities;