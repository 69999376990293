import i1 from './fonds_data/pravo-na-zahyst.png';
import i2 from './fonds_data/fond-europe.png';
import i3 from './fonds_data/palanytzia.png';

// eslint-disable-next-line
export default [
      {
        id: 1,
        name: 'Right to Protection',
        description: [
          'The Right to Protection, Charitable Foundation is one of the leaders of the human rights movement in Ukraine and has a strong position in the field of protection of the rights of IDPs, refugees and stateless persons.',
        ],
        image: i1,
        categories: ['Refugees'],
        link: 'https://r2p.org.ua/help/?lang=en',
        fond: 'https://r2p.org.ua',
      },
      {
        id: 2,
        name: 'East Europe Foundation ("Shelter" Project)',
        description: [
          'The Shelter project aims to provide emergency assistance to those who need it most.',
          'Maintenance of the network of centers for IDPs in the western regions of the country and collection, processing and prompt response to requests for needs from other groups of the population are the main directions of the project.',
        ],
        image: i2,
        categories: ['Refugees'],
        link: 'https://eef.org.ua/program/proyekt-shelter-dopomoga-vymushenym-pereselentsyam/',
        fond: 'https://eef.org.ua',
      },
      {
        id: 3,
        name: 'Palianytsia',
        description: [
          'Ukrainian charity project from Lviv, aimed at helping refugees and injured Ukrainians.',
          "The project provides shelter to refugees arriving in Lviv. They provide humanitarian aid - food, medicine, medical equipment, hygiene products, children's goods - from the EU and other countries and provide psychological and legal assistance to victims.",
        ],
        image: i3,
        categories: ['Refugees'],
        link: 'https://palianytsia.com.ua/',
        fond: 'https://palianytsia.com.ua/',
      },
    ];
  