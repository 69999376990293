import React from 'react';
import './animals_models.css';
import Modal from "../Fond/modal_fond";

function AnimalModels({ animal }) {
    const [isModal, setModal] = React.useState(false);
    return (
            <div className='animal-item'><img
                className='animal-image'
                src={animal.image}
                alt={animal.name}/>
                <p onClick={() => setModal(true)} className='animal-name'>{animal.name}</p>
                <Modal
                isVisible={isModal}
                title={animal.name}
                imagefond={animal.image}
                content={animal.description}
                linkfond={animal.fond}
                onClose={() => setModal(false)}
                />
                <a href={animal.link} className="donate_button_animal">Donate</a>
            </div>
    );
}

export default AnimalModels;