import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../Home/Home.jsx';
import Category from "../Category/Category.jsx";
import Onboard from "../Onboard/Onboard.jsx";
import Fond from "../Fond/fond.jsx"
import Childrens from "../Children/children.jsx"
import Refugees from "../Refugees/refugees.jsx"
import Womans from "../Woman/woman.jsx"
import OldPeople from "../OldPeople/old_people.jsx"
import Disabilities from "../Disabilities/disabilities.jsx"
import Medicine from "../Medicine/medicine.jsx"
import Animal from "../Animals/animals.jsx"
import Army from "../Army/army.jsx"

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<Home/>}/>
        <Route path={'category'} element={<Category/>}/>
        <Route path={'fonds'} element={<Fond/>}/>
        <Route path={'onboard'} element={<Onboard/>}/>
        <Route path={'childrens'} element={<Childrens/>}/>
        <Route path={'refugees'} element={<Refugees/>}/>
        <Route path={'woman'} element={<Womans/>}/>
        <Route path={'older_people'} element={<OldPeople/>}/>
        <Route path={'people_with_disabilities'} element={<Disabilities/>}/>
        <Route path={'medicine'} element={<Medicine/>}/>
        <Route path={'animals'} element={<Animal/>}/>
        <Route path={'army'} element={<Army/>}/>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;